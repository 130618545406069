// .navigation 
//     position: fixed
//     bottom: 0

.region-fragment
    bottom: 60px
    position: fixed

.header
    top: 0px
    z-index: 1
    position: sticky

.z-index-1
    z-index: 1

.z-index-2
    z-index: 2


.md-box
    background: rgba(0, 0, 0, 0.8)
    position: absolute
    width: 100%
    height: 100vh
    top: 0
    left: 0
    z-index: 10

    .md-content
        position: absolute
        background: #D9D9D9F2
        width: 281px
        height: 256px
        gap: 0px
        border-radius: 20px
        opacity: 9
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)

        .md-header
            padding: 25px 15px
            padding-top: 45px

        .title
            font-family: Hiragino Kaku Gothic Pro
            font-size: 20px
            font-weight: 600
            line-height: 30px
            text-align: center
            color: #000000E5
        
        .md-footer
            bottom: 0
            position: absolute
            width: 100%
        
        .btn
            font-family: Hiragino Kaku Gothic Pro
            font-size: 20px
            font-weight: 600
            line-height: 30px
            text-align: center
            color: #007BED
            border-top: 1px solid #cccccc
            padding: 10px


.mypage
    position: absolute

.mypage-change-profile
    z-index: 12
    position: absolute
    width: 100%
    height: 100%

.h-62
    height: 62px

.user-status__coin
    align-items: center
    img
        margin-top: 0px
    span
        margin-top: 0px

.item-found-claimed
    z-index: 11
    position: absolute

.bg-gray
    background: gray

.bg-black
    background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important


.bg-indigo-950
    background-color: rgb(30 27 75 / var(--tw-bg-opacity)) !important

.text-textBlue
    color: rgb(47 58 90 / var(--tw-text-opacity))

.loading-overlay 
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5) /* Adjust opacity as needed */
    display: flex
    justify-content: center
    align-items: center
    z-index: 9999 /* Ensure it's above other elements */

// disable swap down
body
    overscroll-behavior: none