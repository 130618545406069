.map
  background: url('../images/map.png') no-repeat center center
  background-size: cover

  &-label
    position: absolute

    &__title
      font-size: 26px
      text-align: center
      padding: 10px 40px
      background: rgba(black, .6)
      border-width: 3px
      border-style: solid
      border-image: linear-gradient(to right,rgba(black, 0),#FFF2AB,rgba(black,0)) 100% 1
      color: white
      white-space: nowrap

    &__characters
      display: flex
      justify-content: center

      img
        width: 42px
        height: 42px

  // claim
  &-claim
    background: url('../images/bg_kirakira.jpg') no-repeat top center
    background-size: cover

    &-title
      background: url('../images/h1_claim.png') no-repeat bottom center
      background-size: contain
      text-shadow: 0 4px 4px rgba(0, 0, 0, .6)

    &-list
      display: grid

      li
        display: grid
        grid-template-columns: auto 1fr auto minmax(65px, auto)
        gap: 15px
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)
        border-width: 1px
        border-style: solid
        border-image: linear-gradient(to right,rgba(white, 0),white,rgba(white,0)) 100% 1
        align-items: center

      img
        width: 50px

      &__shadow
        background: linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%)
        height: 20vh
        position: fixed
        bottom: 60px
        width: 100%