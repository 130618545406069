.mypage
  background: url('../images/mypage/bg.jpg') no-repeat center center
  background-size: cover


  &__checkbox
    li
      background: url('/images/ico_checkbox_off.svg') no-repeat left 0
      background-size: 20px

      &:has(input:checked)
        background-image: url('/images/ico_checkbox_on.svg')

    input
      display: none
