@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400700&display=swap")

@import "./sass/common.sass"
@import "./sass/navigation.sass"
@import "./sass/toppage.sass"
@import "./sass/user-status.sass"
@import "./sass/modal-parchment.sass"
@import "./sass/startpage.sass"
@import "./sass/map.sass"
@import "./sass/units.sass"
@import "./sass/items.sass"
@import "./sass/mypage.sass"
@import "./sass/characters.sass"
@import "./sass/treasures.sass"
@import "./sass/battle.sass"

@import "./sass/custom.sass"

html,
body,
#root,
.App
  font-family: "Libre Baskerville", Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif
  user-select: none
  color: #452000
  height: 100%