.items
  &__linethrough
    position: relative

    span
      position: relative
      text-shadow: 0 0 3px  rgba(#081834,1) ,0 0 3px  rgba(#081834,1) ,0 0 3px  rgba(#081834,1)
      z-index: 2
      padding: 0 10px

    &::before,
    &::after
      background: linear-gradient(90deg, rgba(154,47,175,1) 0%, rgba(218,146,231,1) 100%)
      height: 2px
      margin: 0 auto
      content: ""
      position: absolute
      top: 13px
      left: 5%
      width: 30%
      z-index: 1

    &::after
      left: unset
      right: 5%
      transform: rotate(180deg)

  &__select-character-ocherBg
    background: linear-gradient(180deg, rgba(124,99,27,0) 13%, rgba(124,99,27,1) 52%)

  &__select-character-whitebg
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.85) 13%, rgba(255,255,255,0.85) 87%, rgba(255,255,255,0) 100%)

  &-seed-select
    background: url(/images/bg_seed1.jpg) no-repeat center center
    background-size: cover

  &-seed-synthesis
    background: url(/images/bg_seed2.jpg) no-repeat center center
    background-size: cover