.navigation
  .selected
    a
      &::before
        content: ""
        position: absolute
        top: -10px
        z-index: 1
        width: 100%
        height: calc(100% + 10px)
        background: url(../images/navigation/bg_selected.png) 0 0px no-repeat
        background-size: cover
