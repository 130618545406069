.units
  &__explore
    background: url("/images/bg_explore.svg") no-repeat left center
    background-size: contain

  &__attack
    background: url("/images/bg_attack.svg") no-repeat left center
    background-size: contain

  &__defence
    background: url("/images/bg_defence.svg") no-repeat left center
    background-size: contain

  &__name
    max-width: 130px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

    @media (max-width: 375px)
      max-width: 90px

  // 汎用かも
  &__linethrough
    position: relative

    span
      position: relative
      background: #F7F3ED
      z-index: 2
      padding: 0 10px

    &::before
      border: 1px solid rgba(black, 10%)
      content: ""
      position: absolute
      top: 5px
      left: 0
      width: 100%
      z-index: 1

  &__magiccircle-bg
    background: url("/images/bg_magic_circle.svg") no-repeat center center
    color: white
    height: 50px
    line-height: 50px
    font-size: 18px
    font-weight: bold