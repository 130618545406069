.modal-parchment
  &__error
    margin-top: 30px
    padding: 1rem
    color: #e00
    background: rgba(#e00, .1)
    border-radius: 3px

  // ページ個別の要素
  &__term-check
    margin-top: 30px

    li
      margin-bottom: 1rem
      font-size: 20px
      font-weight: bold

    label
      font-size: 18px

    a
      text-decoration: underline

      &:hover
        text-decoration: none
