.user-status
  &__coin,
  &__time
    color: white
    font-size: 12px
    border-radius: 100px
    background: #352B19
    height: 32px
    user-select: text

  &__coin
    display: flex
    padding: 0 10px 0 3px

    img
      margin: 2px 8px 2px 2px
      top: 0px
      width: 24px

    span
      margin-top: 8px
