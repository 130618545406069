.battle
  background: url('/images/bg_battle.jpg') no-repeat center center
  background-size: cover

  &__steal-target
    background: linear-gradient(330deg, rgba(88,106,113,1) 0%, rgba(164,187,193,1) 30%, rgba(64,81,88,1) 100%)
    box-shadow: 0 0 8px 3px rgba(#FFF500, .5)

    > div
      background: linear-gradient(0deg, rgba(161,173,179,1) 0%, rgba(104,119,125,1) 100%)

  &__loading-image
    animation: slide 40s linear infinite

  &__thunder1
    animation: .15s steps(3, end) vfxFlip1 infinite, .9s linear .1s fadein both

  &__thunder2
    animation: .18s steps(3, end) vfxFlip2 infinite, .9s linear .1s fadein both

  &__blur-black
    animation: rotate 10s linear infinite

  &__blur-red,
  &__blur-blue
    background: url() no-repeat center center

  @for $i from 1 through 8
    &__bar#{$i}
      opacity: 0
      animation: ghostlyFloat 2s ease-in-out 5s infinite

  &__blue
    &--walk
      animation: 5s linear 0s 1 forwards moveToRight

    &--deadBlowAway
      animation: 2s ease-out 0s 1 forwards blowAwayLeft

    &--deadRolling
      animation: rollingLeft .5s linear infinite

  &__red
    &--walk
      animation: 5s linear 0s 1 forwards moveToLeft

    &--deadBlowAway
      animation: 2s ease-out 0s 1 forwards blowAwayRight

    &--deadRolling
      animation: rollingRight .5s linear infinite

  &__smoke
    opacity: 0
    animation: floatSmoke 5s linear infinite, softBounce .3s linear infinite

    &--remove
      animation: removeSmoke 2s linear 1 forwards

  &__smoke2
    opacity: 0
    animation: smokeJump 1s ease-out infinite

    &--remove
      animation: removeSmoke 2s linear 1 forwards

    &-wrapper
      position: absolute
      width: 20px
      height: 20px

  &__star
    opacity: 0
    animation: starBlink .5s steps(1, end) infinite

    &--remove
      animation: removeSmoke 2s linear 1 forwards

    &-wrapper
      position: absolute
      width: 20px
      height: 20px

  &__result-bg
    opacity: 0
    animation: fadein 1s ease-out 1s both

  &__result-text-win
    opacity: 0
    animation: jumpIn .5s ease-out 2s both

  &__result-text-lose
    opacity: 0
    animation: fadein 2.8s ease-out 2.5s both


  &__ranking
    thead
      background: rgba(red, .1)

      th
        font-weight: normal
    tbody
      tr
        background: rgba(black, .2)
        border-top: 1px solid rgba(#800, .5)

        &:first-child
          border-top: none

      th,
      td
        padding: 5px 10px

        &:nth-child(1)
          width: 50px

        &:nth-child(2)
          width: 100%

        &:nth-child(3)
          width: 80px
          padding-right: 23px

        &:nth-child(4)
          width: 80px
          padding-right: 15px

      td
        &:first-child
          text-align: center
          font-size: 20px
          font-weight: bold

        &:nth-child(2)
          span
            width: 150px
            overflow: hidden
            text-overflow: ellipsis
            white-space: nowrap


    &-crown1,
    &-crown2,
    &-crown3
      font-size: 14px
      text-shadow: 1px 1px 1px rgba(0, 0, 0, .5), -1px 1px 1px rgba(0, 0, 0, .5), -1px -1px 0 rgba(0, 0, 0, .5), 1px -1px 0 rgba(0, 0, 0, .5)
      background: url('/images/battle/crown_gold.svg') no-repeat center center
      background-size: 35px
      text-align: center
      font-weight: bold
      padding-top: 12px !important

    &-crown1
      background-image: url('/images/battle/crown_gold.svg')

    &-crown2
      background-image: url('/images/battle/crown_silver.svg')

    &-crown3
      background-image: url('/images/battle/crown_bronze.svg')



@keyframes fadein
  0%
    opacity: 0
  100%
    opacity: 1


@keyframes jumpIn
  0%
    opacity: 0
    transform: translate3d(0, -10px, 0)
  30%
    opacity: 1
    transform: translate3d(0, -30px, 0)
  60%
    opacity: 1
    transform: translate3d(0, 0px, 0)
  75%
    opacity: 1
    transform: translate3d(0, -10px, 0)
  90%
    opacity: 1
    transform: translate3d(0, 0, 0)
  95%
    opacity: 1
    transform: translate3d(0, -7px, 0)
  100%
    opacity: 1
    transform: translate3d(0, 0, 0)

@keyframes vfxFlip1
  0%
    transform: scale(1, 1) rotate(0deg)
  100%
    transform: scale(1, -2) rotate(5deg)

@keyframes vfxFlip2
  0%
    transform: scale(1, -2) rotate(0deg)
  100%
    transform: scale(1, 1) rotate(5deg)


@keyframes ghostlyFloat
  0%
    transform: translateY(70%)
    opacity: 0
  30%
    opacity: 1
  70%
    opacity: 1
  100%
    transform: translateY(-70%)
    opacity: 0

@keyframes rotate
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes slide
  0%
    transform: translateX(0%)
  100%
    transform: translateX(-100%)

// Tailwind CSSで左右反転させているので、translateXの他に手動設定している
@keyframes moveToLeft
  from
    transform: translateX(0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
  to
    transform: translateX(-20vw) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))

@keyframes moveToRight
  from
    transform: translateX(0)
  to
    transform: translateX(20vw)

@keyframes floatSmoke
  0%
    opacity: 0
    bottom: -1rem
  10%
    opacity: 1
  80%
    opacity: 1
  100%
    opacity: 0
    bottom: 7rem

@keyframes softBounce
  0%
    transform: scale(1)
  50%
    transform: scale(1.2)
  100%
    transform: scale(1)


@keyframes removeSmoke
  0%
    opacity: 1
  100%
    opacity: 0

$blowAwayDistanceX: 180px
$blowAwayDistanceY: -160px

@keyframes blowAwayRight
  0%
    transform: translate(0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    filter: brightness(1) invert(0) opacity(1)
  50%
    filter: brightness(.8) invert(.2) opacity(.8)
  100%
    transform: translate($blowAwayDistanceX, $blowAwayDistanceY) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    filter: brightness(0) invert(1) opacity(0)

@keyframes blowAwayLeft
  0%
    transform: translate(0)
    filter: brightness(1) invert(0) opacity(1)
  50%
    filter: brightness(.8) invert(.2) opacity(.8)
  100%
    transform: translate(-$blowAwayDistanceX, $blowAwayDistanceY) scale(1.2)
    filter: brightness(0) invert(1) opacity(0)

@keyframes rollingRight
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes rollingLeft
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(-360deg)

@keyframes starBlink
  0%
    opacity: 1
  50%
    opacity: 0

@keyframes smokeJump
  0%
    opacity: 1
    top: 0
  100%
    opacity: 0
    top: -180px
