.red-button,
.gray-button
  background: #620101
  background: linear-gradient(0deg, #270303, #620101 100%)

  span
    letter-spacing: 1.8px
    font-size: 18px
    background: -webkit-linear-gradient( #E8DB36,#fff)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent

    &::before,
    &::after
      content: ""
      position: absolute
      top: 0
      left: 3px
      width: calc(100% - 6px)
      height: 100%
      border-left: 1px solid rgba(#FFCE00, .7)
      border-right: 1px solid rgba(#FFCE00, .7)

    &::after
      top: 3px
      left: 0
      width: 100%
      height: calc(100% - 6px)
      border: none
      border-top: 1px solid rgba(#FFCE00, .7)
      border-bottom: 1px solid rgba(#FFCE00, .7)

.gray-button
  background: #343434
  background: linear-gradient(0deg, #343434, #626262 100%)

  span
    background: none
    -webkit-background-clip: text
    -webkit-text-fill-color: white

    &::before,
    &::after
      border-left: 1px solid #eee
      border-right: 1px solid #eee

    &::after
      border: none
      border-top: 1px solid #eee
      border-bottom: 1px solid #eee

.ico-annotation
  display: inline-block
  margin-left: 3px

.screen
  height: calc(100% - 64px - 61px)

.stone
  border-radius: 6px
  background:  linear-gradient(to bottom, #A6B6BB, #6C7E88, #A6B6BB)
  padding: 3px
  line-height: 0
  position: relative

  &--selected
    background:  linear-gradient(to bottom, #e4cd3b, #807d2a, #e3e318)

  &--battle-result
    background:  linear-gradient(to bottom, #1F71ED, #001825, #C21515)

  span
    display: inline-block
    background: linear-gradient(0deg, #848F91 0%, #526066 100%)
    border-radius: 4px

  img
    width: 44px
    height: auto

.triangle
  border-right: 20px solid #fff
  border-bottom: 20px solid #fff
  border-top: 20px solid transparent
  border-left: 20px solid transparent

.checkbox-button
  display: block
  padding-bottom: 25px
  background: url('/images/ico_checkbox_ball_on.svg') no-repeat center bottom
  background-size: 20px
  filter: saturate(0) brightness(0.7)
  transition: .2s

  &:has(input:checked)
    filter: saturate(1) brightness(1)

  input
    display: none
